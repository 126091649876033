@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-route-summary {
  &__pickup-message {
    text-align: center;
    color: $blue1;
    font-size: 17px;
    width: 100%;
  }

  &__loading {
    text-align: center;
    margin: 35vh 0;
  }

  &__failed {
    margin: 32px auto;
    max-width: 400px;
    width: 100%;
  }

  &__confirmation {
    text-align: center;

    &__header {
      font-size: 24px;
    }

    &__buttons {
      margin: 16px auto;
      max-width: 150px;

      .xgs-btn {
        margin: 8px 0;
      }
    }

    &__note {
      margin: 0 auto;;
      color: $gray2;
      font-size: 16px;
      max-width: 410px;
    }
  }

  &__numbers {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0px 0 8px 0;

    &__item {
      margin: 16px 24px;
      padding: 16px;
      border-radius: $border-radius;
      text-align: center;
      font-size: 18px;
      background-color: $gray7;
      color: $gray3;

      @include up-to(460px) {
        margin: 16px 8px;
      }

      &__value {
        font-size: 28px;
        color: $darkblue1;
      }
    }
  }

  &__totals {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 16px 32px 24px 32px;


      @include up-to($tablet-width-up-to) {
        margin: 16px 20px;
      }

      &__icon {
        color: $gray4;
        font-size: 36px;
        margin-right: 16px;

        @include up-to($tablet-width-up-to) {
          font-size: 32px;
          margin-right: 12px;
        }
      }

      &__text {
        color: $gray3;

        span {
          color: $darkblue1;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  &__list {
    margin: 32px auto 48px auto;
    max-width: 640px;

    &__item {
      position: relative;

      &:not(.xgs-route-summary__list__item--sc) .xgs-address-map-button {
        margin: 16px auto 0 auto;
      }

      &__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 84px;
        padding: 16px;
        background-color: $gray6;
        border-radius: $border-radius;

        @include up-to(500px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        &--clickable {
          cursor: pointer;
        }
      }

      &__time {
        margin-top: $margin8;
        color: $gray2;
        svg {
          color: $blue1
        }

        @include up-to(500px) {
          text-align: center;
        }
      }

      &__icon {
        color: $gray4;
        font-size: 24px;
        margin-bottom: 8px;
        margin-left: -4px;
      }

      &__sc-arrow {
        position: relative;
        display: inline-block;
        border: solid $gray5;
        border-width: 0 2px 2px 0;
        padding: 13px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin: -24px 0 1px 18px;
        background-color: #ffffff;

        @include up-to(500px) {
          left: calc(50% - 30px);
        }
      }

      &__arrow {
        position: relative;
        margin: 0 0 0 12px;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $gray6;

        @include up-to(500px) {
          left: calc(50% - 30px);
        }
      }

      &--sc {
        .xgs-route-summary__list__item__box {
          background-color: white;
          border: 2px solid $gray5;
          cursor: inherit;
          padding: 8px 16px;
          height: auto;

          @include up-to(500px) {
            padding-bottom: 16px;
          }
        }

        .xgs-route-summary__list__item__arrow {
          border-top: 20px solid $gray5;
        }

        .xgs-address-map-button {
          margin: 0 auto;
        }

        .xgs-route-summary__list__item__right-column {
          width: 100px;

          @include up-to(500px) {
            padding-top: 16px;
          }
        }
      }

      &--sc-origin {
        .xgs-address-map-button {
          background-color: $gray4;
        }

        .xgs-route-summary__list__item__details__address,
        .xgs-route-summary__list__item__details__info {
          color: $gray3;
        }
      }

      &--past {
        &:hover {
          .xgs-route-summary__list__item__box {
            background-color: $green4;
          }

          .xgs-route-summary__list__item__arrow {
            border-top: 20px solid $green4;
          }
        }

        .xgs-route-summary__list__item__time {
          color: $gray3;
          svg {
            color: $gray3;
          }
        }

        .xgs-route-summary__list__item__box {
          background-color: $green2;
        }

        .xgs-route-summary__list__item__number,
        .xgs-route-summary__list__item__details__address,
        .xgs-route-summary__list__item__details__info {
          color: $gray3;
        }

        .xgs-route-summary__list__item__arrow {
          border-top: 20px solid $green2;
        }

        .xgs-route-summary__list__item__status {
          color: $gray3;
        }

        .xgs-address-map-button {
          background-color: $gray4;
        }
      }

      &--active {
        &:hover {
          .xgs-route-summary__list__item__box {
            background-color: $blue4;
          }

          .xgs-route-summary__list__item__arrow {
            border-top: 20px solid $blue4;
          }
        }

        .xgs-route-summary__list__item__box {
          background-color: $blue3;
        }

        .xgs-route-summary__list__item__number {
          color: $gray0;
        }

        .xgs-route-summary__list__item__details__address {
          font-size: 24px;
        }

        .xgs-route-summary__list__item__details__info {
          font-size: 18px;
        }

        .xgs-route-summary__list__item__arrow {
          border-top: 20px solid $blue3;
        }

        .xgs-route-summary__list__item__status {
          color: $darkblue1;
        }
      }

      &:not(.xgs-route-summary__list__item--past):not(.xgs-route-summary__list__item--active):not(.xgs-route-summary__list__item--sc):hover {
        .xgs-route-summary__list__item__box {
          background-color: $gray5;
        }

        .xgs-route-summary__list__item__arrow {
          border-top: 20px solid $gray5;
        }
      }

      &__number {
        color: $gray3;
        font-size: 42px;
        width: 36px;
        text-align: center;
        margin-right: 16px;

        @include up-to(500px) {
          font-size: 36px;
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      &__details {
        flex-grow: 1;
        flex-shrink: 0;
        max-width: calc(100% - 52px);

        @include up-to(500px) {
          max-width: none;
          width: 100%;
        }

        &__address {
          font-size: 18px;
          margin-bottom: 8px;
          word-break: break-word;

          @include up-to(500px) {
            text-align: center;
          }
        }

        &__info {
          color: $gray2;
          width: 100%;

          @include up-to(500px) {
            text-align: center;
          }

          &__exceptions {
            color: $red2;
          }
        }
      }

      &__info {
        display: flex;
        align-items: center;
        max-width: calc(100% - 100px);
        flex-grow: 1;

        @include up-to(500px) {
          flex-shrink: 0;
          width: 100%;
          max-width: none;
          flex-wrap: wrap;
        }
      }

      &__status {
        width: 100px;
        text-align: center;
        color: $gray1;

        @include up-to(500px) {
          width: 100%;
          padding: 16px 0 4px 0;
        }
      }
    }

    &__no-stops {
      text-align: center;
      font-size: 36px;
      margin: 64px 0;
      color: $gray4;
    }
  }
}
