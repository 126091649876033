@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

$logo-container-width: 80px;

.xgs-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  height: $header-height;
  background-color: $header-back;
  overflow: hidden;
  border-bottom: 1px solid #E0E0E0;

  a {
    text-decoration: none;
  }

  &__start {
    flex-shrink: 0;
    width: calc((100% - #{$logo-container-width}) / 2);
  }

  &__middle {
    width: $logo-container-width;
  }

  &__end {
    width: calc((100% - #{$logo-container-width}) / 2);
    flex: 3;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    @include up-to(600px) {
      flex: none;
      padding-right: 0px;
    }
  }

  .xgs-logo {
    display: block;
    margin: 0 auto;
    height: 54px;
    width: auto;
    max-width: 100%;

    @include mobile {
      width: 100px;
      height: auto;
      max-height: 54px;
    }
  }

  .xgs-badge-number {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 32px;
    width: fit-content;

    &--offline {
      flex-direction: row;
      margin-top: 58px;

      .xgs-badge-number__value {
        order: 2;
        font-size: 12px;
      }

      .xgs-badge-number__label {
        order: 1;
        margin-right: 4px;
        font-size: 12px;
      }
    }

    &__value {
      color: #424D73;
      font-size: 18px;
      font-weight: 600;
      order: 1;
    }

    &__label {
      color: $gray_72;
      font-size: 16px;
      order: 2;
    }
  }
}