@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;

  @include up-to(460px) {
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  &__button {
    margin: 0 8px;
  }

  &__item {
    margin: 4px 16px;
    padding: 16px;
    min-width: 120px;
    border-radius: $border-radius;
    cursor: pointer;
    background-color: $blue1;
    color: white;
    text-align: center;

    @include up-to(460px) {
      width: 100%;
    }

    &:not(.xgs-menu__item--active):hover {
      background-color: $blue2;
    }

    &--active {
      background-color: #ffffff;
      cursor: inherit;
      font-family: "Roboto-Bold";
      color: $blue1;
      border: 2px solid $blue1;
    }
  }
}