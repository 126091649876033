@import "../../styles/variables.scss";

.xgs-additional-details {
  padding: 16px;

  &__heading {
    margin-bottom: 16px;
    font-size: 16px;
  }

  .xgs-table__wrapper {
    margin-bottom: 24px;
    border-bottom: 2px solid $gray_8;
  }
}