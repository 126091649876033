@import "./../../../styles/variables.scss";

.xgs-radio {
  &__group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__item {
      padding: 8px 16px;
      min-width: 120px;
      border-radius: $border-radius;
      background-color: $blue1;
      color: white;
      text-align: center;
      border: 2px solid $blue1;

      &:not(.xgs-radio__group__item--active):hover {
        cursor: pointer;
        background-color: $blue2;
        border: 2px solid $blue2;
      }

      &--active {
        background-color: #ffffff;
        cursor: inherit;
        color: $blue1;
      }

      &--error {
        border: 1px solid $red;
      }

      &__label {
        cursor: inherit;
        font-size: 14px;
      }
    }
  }
}
