@import "./../../styles/variables.scss";

.xgs-table__wrapper {
  position: relative;
  display: block;
  height: 100%;
  overflow-x: auto;

  &--loading {
    overflow-x: visible;
  }
}

.xgs-table {
  position: relative;
  // display: flex;
  // justify-content: stretch;
  // flex-wrap: wrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
  overflow-x: auto;
  overflow-y: hidden;

  &__headers {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $gray_8;

    .th {
      color: $gray_72;
      font-weight: bold;
    }

    &--sorting {
      .th:hover {
        cursor: pointer;
        color: $gray3;
      }
    }

    &__sortable-item {
      position: relative;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &__icon {
        position: absolute;
        right: 16px;
        top: 7px;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .th,
  .td {
    font-size: 14px;
    margin: 0;
    padding: 0.5rem;
    flex-shrink: 0;
    word-wrap: break-word;
  }

  .tr {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &__full-width {
    width: 100% !important;

    & > div {
      width: 100% !important;      
    }

    .td,
    .th {
      flex-shrink: 1 !important;
    }
  }

  &__scroll-spacer {
    width: 16px;
    flex-shrink: 0 !important;
  }

  &__even-row {
    background-color: #fafafa;
  }

  &__highlighted-row:not(.xgs-table__group-row-header, .xgs-table__group-row) {
    box-shadow:
      inset 0 -1px 0 #2F80ED,
      inset 0 1px 0 #2F80ED !important;
    background: #DDEBFF;
  }

  &__highlighted-row.xgs-table__group-row-header,
  &__highlighted-row.xgs-table__group-row {
    background-color: $purple7;
  }  

  &__highlighted-row.xgs-table__even-row.xgs-table__group-row {
    background-color: $purple3;
  }  

  &__spinner-row {
    .td {
      width: 100%;
      text-align: center;
    }
  }

  .tr:not(.xgs-table__headers):not(.xgs-table__spinner-row):not(.xgs-table__group-row):not(.xgs-table__group-row-header):hover {
    background-color: rgba(47, 128, 237, 0.08);
  }

  &__group-row,
  &__group-row-header {
    background-color: $purple5;

    &.tr:hover {
      background-color: $purple4;
    }

    .td:first-child {
      background-color: $purple4;
    }
  }

  &__even-row.xgs-table__group-row {
    background-color: $purple6;
  } 

  .tr:not(.xgs-table__highlighted-row) + .xgs-table__group-row-header:not(:first-child) {    
    box-shadow: inset 0 1px 0 $purple2;

    .td:first-child {
      box-shadow: inset 0 1px 0 $purple2;
    }
  }

  .xgs-table__group-row + .tr:not(.xgs-table__group-row) {    
    box-shadow: inset 0 1px 0 $purple2;    
  }  

  &__group-label {
    color: $purple2;
    font-weight: 600;
    font-size: 14px;
  
    &__tag {
      display: inline;
      margin-left: 5px;        
    }
  }

  &__loading-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 998;
  }

  &__loading {
    height: 120px;
    width: 100%;
    text-align: center;
    padding-top: 50px;

    &--over {
      position: absolute;
      left: calc(50% - 16px);
      top: 80px;
      z-index: 999;
      width: auto;
    }
  }

  &__no-records {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  &__cell-content {
    &--centered {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  &__cell-subitem {
    margin-left: 8px;
    color: $gray_72;
  }

  &__tooltip {
    display: inline-block;
    margin-left: 5px;
  }
}