@import "./variables.scss";
@import "./mixins.scss";

.xgs-card {
  &__block {
    position: relative;
    background-color: $gray7;
    border: 2px solid $gray6;
    border-radius: $border-radius;
    margin-bottom: 24px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: $gray6;
    }

    &--no-interaction {
      &:hover {
        background-color: $gray7;
      }
    }

    &--active {
      border: 3px solid $blue4;
    }

    &--w-label {
      @include up-to(460px) {
        padding-top: 32px;
      }
    }

    &__label {
      position: absolute;
      top: 16px;
      right: 16px;
      display: inline-block;
      height: 20px;
      padding: 0px 6px;
      border-radius: $border-radius;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;

      &--active {
        background: rgba(47, 128, 237, 0.16);
        color: #2F80ED;
      }

      &--completed {
        background: rgba(93, 199, 84, 0.16);
        color: #14870A;
      }
    }
  }

  &__numbers {
    align-items: flex-start;

    &__item {
      margin: 0 24px;
      padding: 16px;
      border-radius: $border-radius;
      text-align: center;
      font-size: 18px;
      color: $gray3;

      &__value {
        font-size: 24px;
        color: $darkblue1;

        &--inline {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  &__totals {
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 12px 20px;

      &__icon {
        color: $gray4;
        font-size: 36px;
        margin-right: 16px;
        display: flex;
        align-items: flex-end;

        @include up-to($tablet-width-up-to) {
          font-size: 32px;
          margin-right: 12px;
        }
      }

      &__text {
        color: $gray3;

        span {
          color: $darkblue1;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  &__line {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__pair {
    text-align: center;
    margin: 16px 8px 16px 8px;
    font-size: 16px;
    color: $gray3;

    span {
      color: $darkblue1;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.xgs-notification-block {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 14px 12px;
  border-radius: $border-radius;
  border: 1px solid rgba(79, 95, 114, 0.32);
  background: #ffffff;

  img {
    display: block;
  }

  &__text {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
  }
}
