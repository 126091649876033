@import "./variables.scss";
@import "./mixins.scss";

.xgs-line-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: #828282;
  margin-bottom: 16px;

  &:before,
  &:after {
    content: "";
    border-top: 1px solid #E0E0E0;
    flex: 1 0 auto;
  }

  &:before {
    margin: 0 8px 0 0;
  }

  &:after {
    margin: 0 0 0 8px;
  }
}
