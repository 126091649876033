@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-stop-details-sticky-header {
  position: fixed;
  width: 100%;
  padding: 5px;
  top: 0;
  z-index: 10;
  background-color: inherit;
  left: 0;
  transition: transform 0.3s ease-in-out;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: $gray2;
  display: none;
  @include mobile {
    display: block;
  }
  @include tablet {
    display: block;
  }
  &--show {
    transform: translateY(0%);
  }
  &--hide {
    transform: translateY(-100%);
  }
}