@import-normalize;
@import "./../styles/variables.scss";
@import "./../styles/mixins.scss";

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.ttf");
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
  min-height: 100vh; // fix iOS 15 black area at the bottom
}

body {
  margin: 0;
  font-family: "Roboto-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xgs-site {
  &__wrapper {
    padding: 16px;
  }

  &__content {
    position: relative;
    height: 100%;
    min-height: calc(100vh - #{$header-height});
    width: 100%;
    padding: $margin6;
  }

  &__section-header {
    color: $gray_72;
    text-align: center;    
    font-size: 20px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 16px;

    span {
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
    }

    &::before,
    &::after {
      content: "";
      height: 1px;
      background-color: $gray_32;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      position: relative;
    }

    &::before {      
      margin-left: -100%;
    }

    &::after {
      margin-right: -100%;
    }
  }

  .bold {
    font-family: "Roboto-Bold";
  }

  .clickable {
    cursor: pointer;
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }

  .flexbox {
    display: flex;
  }

  &__box__link {
    font-size: 14px;
    line-height: 16px;
    color: $gray3;

    a {
      color: $blue1;
      text-decoration: none;
    }
  }
}

.Toastify__toast--error {
  background-color: $red;
  color: white;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.block-center {
  margin: 0 auto;
}

.blue-link {
  color: $blue1;
  text-decoration: none;
  cursor: pointer;
}

.red-link {
  cursor: pointer;
  color: $red;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.xgs-list {
  &__controls {
    max-width: 1124px;
    margin-top: -32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $margin7;

    @include mobile {
      margin-top: 0;
    }

    @include up-to(450px) {
      width: 100%;
    }

    &__search,
    &__actions {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    &__search__input {
      width: 280px;
      flex-shrink: 0;
      margin-right: $margin7;

      @include up-to(560px) {
        width: 220px;
      }

      @include up-to(510px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &__button {
      &:not(:last-child) {
        margin-right: $margin7;

        @include up-to(450px) {
          margin-right: 0;
        }
      }

      @include up-to(640px) {
        width: 100px;
      }

      @include up-to(510px) {
        margin-top: $margin8;
      }

      @include up-to(450px) {
        width: 100%;
      }
    }
  }

  &__table-wrapper {
    max-width: 1024px;
    height: calc(100vh - 242px);

    @include mobile {
      height: calc(100vh - 264px);
    }

    @include up-to(642px) {
      height: calc(100vh - 308px);
    }
  }

  &__table {
    height: 100%;
  }
}

.xgs-sidebar__details {
  &__name-block {
    display: flex;
    align-items: flex-start;
    margin-bottom: $margin6;
  }

  &__avatar {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 64px;
    background-color: $gray4;
    color: white;
    text-align: center;
    margin-right: $margin7;
    margin-bottom: $margin7;
    border-radius: $border-radius;
  }

  &__name,
  &__title {
    word-break: break-all;
    font-size: 24px;
    line-height: 28px;
  }

  &__title {
    color: $gray4;
    font-size: 14px;
    margin-bottom: 6px;
  }

  &__rows {
    line-height: 1.7;
    font-size: 14px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4px;

    @include up-to(500px) {
      margin-bottom: 8px;
    }
  }

  &__label {
    width: 160px;
    flex-shrink: 0;

    &:not(.xgs-sidebar__details__label--fixed) {
      @include up-to(500px) {
        width: 100%;
      }
    }
  }

  &__value {
    word-break: break-word;
  }
}

.xgs-form {
  &__checkbox {
    label {
      display: flex;
      align-items: center;
    }

    input {
      width: 20px;
      height: 20px;
      border: 1px solid $gray3;
      border-radius: 4px;
    }

    &__label {
      font-size: 14px;
      margin: 5px 8px 5px 0;
    }
  }

  &__textarea {
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    transition: all 100ms;
    font-size: 14px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(52, 182, 255, 0.5) !important;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 24px;

    span {
      color: $form-validation-error-color;
    }
  }
}

.ReactModal__Content.ReactModal__Content--after-open {
  @include up-to(480px) {
    padding: 37px 16px 24px !important;
  }
}

.xgs-gallery {
  z-index: 1002 !important;
  background-color: rgba(0, 0, 0, 0.8);

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    z-index: 1003;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;

    &__text {
      margin: 16px 0 0 16px;
    }

    &__close-icon {
      position: absolute;
      top: 2px;
      right: 24px;
      cursor: pointer;
      width: 48px;
      height: 48px;
    }
  }

  &__prev,
  &__next {
    z-index: 1003;
    cursor: pointer;
    position: absolute;
  }

  &__prev__icon,
  &__next__icon {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 4px;
    width: 32px !important;
    height: 48px !important;
  }

  &__prev {
    left: 8px;
  }

  &__next {
    right: 8px;
  }
}

.xgs-upload {
  &__notes {
    color: $gray2;
    margin-top: 4px;
    width: 100%;
  }

  &__area {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: $gray6;
    padding: 24px 16px;
    max-width: 600px;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: 14px;
    text-align: center;

    @include up-to(330px) {
      padding: 12px;
    }

    &__error {
      color: $red;
      margin-top: 16px;
      width: 100%;
    }

    &__icon {
      margin-right: 8px;
      font-size: 24px;
      color: $blue1;
    }
  }
}

.xgs-spinner {
  &--center {
    text-align: center;
    margin: 35vh 0;
  }
}

.xgs-space {
  &__top {
    &__48 {
      margin-top: 48px;
    }
  }
}
