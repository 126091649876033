@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-offline-notifier {
  position: fixed;
  top: -1px;
  left: 16px;
  max-width: 178px;
  padding: 4px 8px;
  text-align: center;
  background-color: $gray6;
  border-radius: $border-radius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid $red;
  z-index: 999;

  &__icon {
    display: inline-block;
    color: $gray4;
    margin: 0 4px 0 0;
    width: 28px;
    vertical-align: middle;
  }

  &__header {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: $red;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  &__text {
    font-size: 12px;
    color: $gray2;
    margin-top: -6px;
  }
}