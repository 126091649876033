@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-route-selector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 16px;

  &__header {
    width: 100%;
    text-align: center;
    margin: 16px 16px 12px 16px;
    color: $gray2;
    font-size: 22px;
    line-height: 1.4;
  }

  &__subheader {
    color: $gray3;
    font-size: 18px;
    margin: 0 16px 32px 16px;
  }

  &__no-route {
    margin-top: 20vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--w-pickups {
      margin-top: 64px;
    }

    &__header {
      font-size: 38px;
      max-width: 440px;
      margin-bottom: 64px;
    }

    &__contact {
      color: $gray3;

      &__header {
        font-size: 22px;
        margin-bottom: 8px;
      }
    }
  }
}
