@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.xgs-web-clock {
  padding: 24px 40px;

  &.xgs-card__block {
    cursor: auto;
  }

  &__buttons {
    display: flex;
    gap: 12px;
    margin-top: 12px;

    @include up-to(460px) {
      flex-direction: column;
    }
  }

  &__action-button {
    width: 100%;
  }

  &__menu-button {
    width: 62px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include up-to(460px) {
      width: 100%;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    margin-bottom: 8px;

    &--inactive {
      .xgs-web-clock__item-value,
      .xgs-web-clock__item-label {
        color: rgba(79, 95, 114, 0.32);
      }      
    }
  }

  &__item-label {
    color: $gray_72;
    font-size: 16px;
    font-weight: 400;

    &--lg {
      font-size: 18px;
    }
  }

  &__item-value {
    color: #424D73;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    &--lg {
      font-size: 32px;
    }

    &--inline {
      padding-left: 8px;
    }
  }

  &__confirmation-text {
    padding: 24px 0;
  }

  &__terminal-selector {
    margin-bottom: 24px;
  }

  &__terminal-selector>div {
    width: 100%;
  }
}