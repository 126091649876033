@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-pickups-list {
  margin: 64px 0;
  width: 100%;

  &__loading {
    text-align: center;
  }
}
