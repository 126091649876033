@import "../../../styles/variables.scss";

.xgs-sign-shipment {
  &__field {
    margin-bottom: $margin7;
  }

  &__notes {
    margin-bottom: $margin7;

    textarea {
      border-color: $gray4;
      border-radius: 4px;
      min-height: 60px;
    }
  }

  &__buttons {
    margin: 24px auto 0 auto;
    display: flex;
    gap: 16px;
    justify-content: center;

    .xgs-btn {
      width: 50%;
    }
  }

  &__sign-pad {
    width: 100%;
    height: 200px;
    border: 1px solid $gray4;
    border-radius: $border-radius;
    position: relative;
  
    &__canvas {
      height: 100%;
      width: 100%;
    }
    &__preview {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 40px;
      pointer-events: none;
      border: 1px solid $gray4;
      border-radius: $border-radius;
      border-right: 0px;
      border-bottom: 0px;
    }
    &--error {
      border-color: $form-validation-error-color;
    }
    &__error {
      color: $form-validation-error-color;
      font-size: 12px;
      margin-bottom: $margin8;
    }
    &__placeholder {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      color: rgba($color: #000000, $alpha: 0.5);
      font-size: 20px;
    }
  }


  &__add-email {
    margin-top: 16px;
    color: $blue1;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }

  .xgs-notification-block {
    margin-top: 24px;
  }
}